import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Hut Architecture',
    url: '/hut-architecture',
  },
]

const Hut = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Hut Architecture"
      description="R&D Hut Architecture"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Hut Architecture"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Hut Architecture" />
          <CaseStudyContent
            heading=""
            paragraph='"I had a limited understanding of R&D tax relief before
            engaging RDA. I had received a few emails but I
            wasn’t sure that it applied to our business.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  We’ve been really pleased with the process and the outcome
                  we’ve had with Kevin and his team. So much so, we have now
                  done this three times together, with great results each time.
                </p>
                <p>
                  The level of support has always been fantastic - it had
                  initially seemed as if it might be an intimidating and
                  time-consuming administrative minefield but RDA really made
                  things simple for HUT to such an extent that my colleagues are
                  now working with RDA — and that means I can get on with
                  running the business. Absolutely, I have done already, and
                  they in turn have been very happy with the outcomes."
                </p>
                <h6>Hut Architecture</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default Hut
